@charset "utf-8";

// Our variables
//$base-font-family: 'Roboto Mono', monospace;
//$base-font-size:   16px;
//$base-font-weight: 400;
//$small-font-size:  $base-font-size * 0.875;
//$base-line-height: 1.5;

//$spacing-unit:     30px;

$text-color:       #ffffff;
$background-color: #000;
//$brand-color:      #2a7ae2;

//$grey-color:       #828282;
//$grey-color-light: lighten($grey-color, 40%);
//$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
//$content-width:    800px;

//$on-palm:          600px;
//$on-laptop:        800px;



// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;

//         padding-left: $spacing-unit / 2;
//     }
// }
//@mixin media-query($device) {
//    @media screen and (max-width: $device) {
//        @content;
//    }
//}


body {
    //font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
    color: $text-color;
    background-color: $background-color;
    //-webkit-text-size-adjust: 100%;
    //-webkit-font-feature-settings: "kern" 1;
    //-moz-font-feature-settings: "kern" 1;
    //-o-font-feature-settings: "kern" 1;
    //font-feature-settings: "kern" 1;
    //font-kerning: normal;
}


// Import partials from `sass_dir` (defaults to `_sass`)
//@import
//        "base",
//        "layout",
//        "syntax-highlighting"
//;

//* {
//	padding: 0;
//	margin: 0;
//}
.main {
	//display: grid;
	//grid-template-columns: 25vw 24vw 24vw 24vw;
	//grid-template-areas:
	//  "header content content content"
	//  "footer footer  footer  footer";
	font-family: 'Roboto Mono', monospace;
	text-align: justify;
	font-size: 14px;
	color: #ffffff;

}

.txt-center {
  text-align: center;
}

.header {
	//grid-area: header;
	//padding: 50px 50px 30px;
	margin-top: 40px;
	p:first-child {
		//width: 230px;
		//line-height: 1em;
		//padding-bottom: 30%;
	}
	.menu {
	  padding: 80px 8px;
	}
	a {
		font-size: 13px;
		color: #fff;
		p {
			font-size: 14px;
			color: #fff;
			text-decoration: none;
		}
		p:hover {
			color: #fff;
		}
	}
	.mainsidebar:hover {
  		background-color: #fff;
  		color: #000;
  		text-decoration: none;
  	}

  	span {
  		font-size: 10px;
  		p {
  			padding-bottom: 0;

  		}
  	}
  	ul {
  		margin: 15px 0px 15px 0px;
  		
  		li {
  			display: inline;
  		}
  		
  	}
}

//.content {
//	//grid-area: content;
//	//padding: 50px 0px 0px;
//}
.card {
	width: 263px;
	height: 195px;
	margin: 40px 30px;
	background-color: black;
	span {
		text-transform: uppercase;
		font-weight: bold;
		font-size: 14px;
		color: #1756a9;
    writing-mode: vertical-rl;
    position: absolute;
    left: 278px;
	}
	a {
		color: #fff;
	}
	a:hover {
		background: #f23a39;
	}
	p {
	  margin: 0;
	  position: absolute;
	  bottom: -22px;
	}
	img {
	  width: 263px;
	  height: 195px;
	}
}

.footer {
	//grid-area: footer;
	//position: absolute;
  //bottom: 0;
  //width: 100%;
  text-align: center;
  padding-bottom: 1%;
  font-size: 8pt;

}
//.poster {
//	grid-column: 1 / 3;
//	grid-row: 2 / 3;
//}

.pagina {
	//float: right;
	//padding: 10% 10% 5%;
	text-align: right;
	a { 
		color: #fff;
	}
	a:hover { 
		background: #fff;
		color: #000;
	}
	li {
		display: inline;
		color: #fff;
    	display: inline;
	}
}
	//#prospeccoes , #afetivas {
	//    margin-left: -40px;
	//}

.sobre {
	padding: 8em 0 0 2em;
 	width: 900px;
  background: url(/assets/quote.svg) 0 0;
  background-repeat: no-repeat;
  background-size: 80px;
	background-position: 1em 5.5em;
  font-family: 'EB Garamond', serif;
  h5 {
    font-size: 1.5em;
    line-height: 1.2;	
  }
  h4 {
  	font-size: 1.3em;
  	color: #aaa;
    font-weight: 100;
  }
	.nome-autor {
		  color : #666;
		  text-transform: uppercase;
	    font-style: italic;
	    padding-right: 1em;
	    text-align: right;
		}
}

.ficha-tecnica {
	padding: 3em 0px 0px 2em;
  	width: 900px;	
  h1 {
		font-size: 15pt;
		padding-bottom: 4em;
	}
	p {
		color: #ccc;
		text-align: center;
	}
	b {
		color: #ccc;
	}
	span {
		font-size: 9.5pt;
	}
}

// OBRAS

.obras {
	padding: 2em 2em 0 0;
    width: 900px;


	//width: 280%;
	blockquote {
		padding: 3px 19px;
		//float: right;
		//width: 50%;
		font-size: 12px;
		border-left: 3px solid #eee;
		strong {
			font-size: 14px;
		}
	}
	//li {
	//	display: inline-block;
	//}
	//span:target {
	//	background: #ccc;
	//}

	h3 {
		font-size: 10pt;
		color: #F23A39;
	}
	h2 {
		font-size: 14px;
		text-align: left;
		color: #fff;
		text-transform: uppercase;
	}
	h2, h3 {
		padding: 6px 2em;
	}
	//img {
	//	width: 100%;
	//	border: none;
	//	background: none;
	//}

	
}

.nav-item,
#default-tab,
#sobre-tab,
#artista-tab {
	width: 25px;
  height: 24.5px;
}

.nav .nav-link {
  border: 0;
  padding: 0;
}

.nav li {
  margin: 2px;
}

/* TABS */
//.itab4 {
//	width: 24.5px;
//    height: 24.5px;
#artista-tab {
    background: url(/assets/buttons.svg) 51px 0;
  //  position: absolute;
  //  margin-left: 90px;
}
//.itab3 {
//	width: 24.5px;
//    height: 24.5px;

//.itab2 {
//	width: 24.5px;
//    height: 24.5px;
#sobre-tab {
    background: url(/assets/buttons.svg) 0 0;
    //position: absolute;
    //background-repeat: no-repeat;
    //margin-left: 30px;
}
//.itab1 {
//	width: 24.5px;
//    height: 24.5px;
#default-tab {
    background: url(/assets/buttons.svg) 25px 0;
    //position: absolute;
}

.aba {
	padding: 30px 0px 10px 0px;
}
//div.tabs {
//  min-height: 100%;		
//  position: relative;		
//  z-index: 0}			
//
//div.tabs > div {
//  display: inline}		
//
//div.tabs > div:target > a,	
//:target #default > a {		
//  border-bottom: 0.1em solid #CCC; 
//  background: #CCC}	
//
//div.tabs > div > div {
//  background: #000;		
//  z-index: -2;			
//  left: 0; top: 1.3em;		
//  bottom: 0; right: 0;		
//  overflow: auto;		
//  }	
//
//div.tabs > div:not(:target) > div { 
//  position: absolute }		
//
//div.tabs > div:target > div, :target #default2 > div {
//  position: absolute;		
//  z-index: -1}			
//
//div.tabs :target {outline: none}

/* IFRAME 
.wrap
{
    width: 963px;
    height: 576px;
    padding: 0;
    overflow: hidden;
}
*/
.frame
{
	width: 100%;
    border: none;
	height: 684px;
//    border: 0;
//
//    -ms-transform: scale(0.75);
//    -moz-transform: scale(0.75);
//    -o-transform: scale(0.75);
//    -webkit-transform: scale(0.75);
//    transform: scale(0.75);
//
//    -ms-transform-origin: 0 0;
//    -moz-transform-origin: 0 0;
//    -o-transform-origin: 0 0;
//    -webkit-transform-origin: 0 0;
//    transform-origin: 0 0;
}

.fyoutube {
	width: 952px;
    height: 535.5px;	
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
  	.frame {
	  	width: 22em;
	  	height: 36em;
	    padding-right: 0.5em;
    }
 .fyoutube {
    width: 330px;
    height: 185px;
}

	.sobre {
	 	hyphens: auto;
	 }
 
 }

/* MENU MOBILE */

#menuFloat {
	color: #1656A9;
    font-size: 3.7em;
	padding-top: 3em;
}
.menuMob {
    z-index: 2;
    position: fixed;
    overflow: hidden;
    top: -9px;
    left: 0;
    padding: 1em 0em 0em 1.5em;
    cursor: pointer;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 6em;
  background: #000000f7;
}

.sidenav a {
  padding: 10px 8px 8px 32px;
  text-decoration: none;
  display: block;
  transition: 0.3s;
}

.sidenav .closebtn {
  position: absolute;
  color: #1656A9;
  top: 0;
  padding-top: 0px;
  font-size: 50px;
}
